import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import arrayMove from "array-move"
import { axios_instance, exposeServerError, Viewer_URL } from "../util/server"
import { t } from "../intl"
import { setStack, addStackCard, setStackCards, setStackHome, setStackPublished, setCurrentCard, updateSessionStackHome, bumpSessionStackToFirst, openPopup } from "../actions"
import StackEmbed from "./StackEmbed"
import { inspectorsRegistry, createCard } from "./Inspector/cardInspectors"
import CardList from "./GUI/CardList"
import "./Creator.scss"
import CardCreate from "./CardCreate"
import Toolbar from "./GUI/Toolbar"
import ShareOptions from "./GUI/ShareOptions"
import StateButton from "./GUI/StateButton"
import CardInspector from "./Inspector/CardInspector"
import { STAR_ADD_NEW_ITEM, STAR_CREATE_NEW_CARD, TEMPLATE_PRODUCT_CATALOG, TEMPLATE_RESTAURANT_MENU, TEMPLATE_SIMPLE_RESTAURANT, TEMPLATE_LANDING_WITH_PRICING_CONTACT, TEMPLATE_LANDING_WITH_CATEGORIES_CONTACT } from "./GUI/GuidingStar"

const Stack_View = "stack"
// const Preview_View = "preview"
const Inspector_View = "inspector"

class Creator extends Component {
	static propTypes = {
		stackKey: PropTypes.string.isRequired,
		onClose: PropTypes.func.isRequired
	}

	state = {
		view: Stack_View,
		showPipPreview: true,
		waitingServer: true,
		savingToServer: false,
		publishingToServer: false,
		unsavedChanges: false,
		closing: false
	}

	async fetchStack () {
		try {
			this.setState({ waitingServer: true })
			const response = await axios_instance.get(`/stacks/${this.props.stackKey}.json?session_token=${this.props.sessionToken}`)
			let stack = response.data.stack
			this.props.setStack({
				...stack,
				currentCard: stack.home,
				fullyLoaded: true
			})
		} catch (e) {
			exposeServerError(e)
		}
		this.setState({ waitingServer: false })
	}

	async componentDidMount () {
		if (this.props.fullyLoaded) {
			this.setState({ waitingServer: false })
			// Updates contents of the stack preview. It'll look outdated if user goes back without making changes.
			this.props.updateSessionStackHome(this.props.stackKey, this.getCard(this.props.stack.home))
		} else {
			this.fetchStack(this.props.stackKey)
		}
	}

	async onNewCard (cardType = "details") {
		if (this.props.currentCardLock > 0) return
		const card = createCard(cardType)
		await this.props.addStackCard(card)
		this.props.setCurrentCard(card.id)
		this.autoSave()
	}
	
	saveStack () {
		return axios_instance.post("/stacks/save", {
			session_token: this.props.sessionToken,
			stack: {
				key: this.props.stackKey,
				home: this.props.stack.home,
				cards: this.props.stack.cards
			}
		})
			.then(() => {
				this.setState({ unsavedChanges: false })
				this.props.setStackPublished(false)
				this.props.updateSessionStackHome(this.props.stackKey, this.getCard(this.props.stack.home))
				this.props.bumpSessionStackToFirst(this.props.stackKey)
			})
			.catch(e => exposeServerError(e))
	}

	autoSave () {
		this.setState({ unsavedChanges: true })
		if (this.state.savingToServer) {
			this.queuedAutoSave = true
			return
		}
		if (this.autoSaveTimeout) {
			clearTimeout(this.autoSaveTimeout)
		}
		this.autoSaveTimeout = setTimeout(() => {
			this.setState({ savingToServer: true })
			this.saveStack()
				.finally(() => this.setState({ savingToServer: false }))
			if (this.queuedAutoSave) {
				this.queuedAutoSave = false
				this.autoSave()
			}
		}, 2000)
	}

	onPublish () {
		this.setState({ waitingServer: true, publishingToServer: true })
		axios_instance.post("/stacks/publish", {
			session_token: this.props.sessionToken,
			stack_key: this.props.stackKey
		})
			.then(() => {
				this.props.setStackPublished(true)
			})
			.catch(console.error)
			.finally(() => this.setState({ waitingServer: false, publishingToServer: false }))
	}

	cloneCurrentCard () {
		return Object.assign({}, this.getCard(this.props.currentCard))
	}

	updateCurrentCard (modifiedCard) {
		const cardIndex = this.getCardIndex(this.props.currentCard)
		const cards = this.props.stack.cards.concat()
		cards[cardIndex] = modifiedCard
		this.props.setStackCards(cards)
	}

	onInspectorDataChange (newData) {
		const modifiedCard = this.cloneCurrentCard()
		modifiedCard.data = {
			...modifiedCard.data,
			...newData
		}
		this.updateCurrentCard(modifiedCard)
		this.autoSave()
	}

	onInspectorNameChange (newName) {
		const modifiedCard = this.cloneCurrentCard()
		modifiedCard.name = newName
		this.updateCurrentCard(modifiedCard)
		this.autoSave()
	}

	onCardSelectChange (card) {
		if (this.props.currentCardLock > 0) return
		this.props.setCurrentCard(card.id)
		this.setState({ view: Inspector_View })
	}

	onCardReorder (fromIndex, toIndex) {
		const cards = arrayMove(this.props.stack.cards, fromIndex, toIndex)
		this.props.setStackCards(cards)
		this.autoSave()
	}

	onCardDuplicate (index) {
		const cards = this.props.stack.cards.concat()
		const newCard = {
			...this.props.stack.cards[index],
			id: CardInspector.generateCardId()
		}
		cards.splice(index + 1, 0, newCard)
		this.props.setStackCards(cards)
		this.autoSave()
	}

	onCardDelete (index) {
		const cards = this.props.stack.cards.concat()
		const deletedId = this.props.stack.cards[index].id
		if (this.props.stack.home === deletedId) {
			alert(t("cantDeleteHomeCard"))
		} else {
			if (window.confirm(t("confirmDeletingCard"))) {
				if (this.props.currentCard === deletedId) {
					const closestCard = this.props.stack.cards[index - 1] || this.props.stack.cards[index + 1]
					this.props.setCurrentCard(closestCard.id)
				}
				cards.splice(index, 1)
				this.props.setStackCards(cards)
				this.autoSave()
			}
		}
	}

	getCardIndex (id) {
		const card = this.getCard(id)
		return this.props.stack.cards.indexOf(card)
	}

	getCard (id) {
		return this.props.stack.cards.find(cardData => cardData.id === id)
	}

	getPublishedUrl () {
		return `${Viewer_URL}/${this.props.stackKey}`
	}

	onCloseStack () {
		if (!this.state.unsavedChanges) {
			this.setState({ closing: true })
			setTimeout(() => {
				this.props.onClose()
			}, 400)
		}
	}

	onShare (e) {
		this.props.openPopup(
			{
				style: {
					top: e.pageY,
					left: e.pageX,
					transformOrigin: `left top`
				},
			},
			ShareOptions,
			{
				publishedUrl: this.getPublishedUrl(),
				qrCodeFilename: this.props.stackKey
			}
		)
	}

	getInspectorGuidingStarLocations () {
		const card = this.getCard(this.props.currentCard)
		const locationsByFields = {}

		if (this.props.stackTemplate === TEMPLATE_RESTAURANT_MENU) {
			if (card.type === "cardList") {
				const items = card.data.items
				const hasPendingNewCard = items && items.some(item => !item.cardId)
				if (!items || items.length === 0) {
					locationsByFields.cards = [STAR_ADD_NEW_ITEM]
				}
				if (hasPendingNewCard) {
					locationsByFields.cards = [STAR_CREATE_NEW_CARD, "pricingList"]
				}
			}
			if (card.type === "pricingList") {
				const items = card.data.items
				if (!items || items.length === 0) {
					locationsByFields.items = [STAR_ADD_NEW_ITEM]
				}
			}
		}

		if (this.props.stackTemplate === TEMPLATE_SIMPLE_RESTAURANT) {
			if (card.type === "pricingList") {
				const items = card.data.items
				if (!items || items.length === 0) {
					locationsByFields.items = [STAR_ADD_NEW_ITEM]
				}
			}
		}

		if (this.props.stackTemplate === TEMPLATE_PRODUCT_CATALOG) {
			if (card.type === "cardList") {
				const items = card.data.items
				const hasPendingNewCard = items && items.some(item => !item.cardId)
				if (!items || items.length === 0) {
					locationsByFields.cards = [STAR_ADD_NEW_ITEM]
				}
				if (hasPendingNewCard) {
					locationsByFields.cards = [STAR_CREATE_NEW_CARD, "details"]
				}
			}
		}

		if (this.props.stackTemplate === TEMPLATE_LANDING_WITH_PRICING_CONTACT) {
			if (card.type === "details" && card.id === this.props.stack.home) {
				const items = card.data.callsToAction
				const hasPendingNewAction = items && items.some(item => !item.action.type || item.action.type === "none")
				const hasPendingNewCard = items && items.some(item => item.action.type === "card" && !item.action.cardId)
				if (!items || items.length === 0) {
					locationsByFields.cta = [STAR_ADD_NEW_ITEM]
				}
				if (hasPendingNewAction) {
					locationsByFields.cta = ["card"]
				}
				if (hasPendingNewCard) {
					locationsByFields.cta = [STAR_CREATE_NEW_CARD, "contact", "pricingList", "cardList"]
				}
			}
		}

		if (this.props.stackTemplate === TEMPLATE_LANDING_WITH_CATEGORIES_CONTACT) {
			if (card.type === "landing" && card.id === this.props.stack.home) {
				const items = card.data.items
				const didNotSetContactCard = !card.data.contactCard && !card.data.contactCard.cardId
				const hasPendingNewAction = items && items.some(item => !item.cardId)
				if (didNotSetContactCard) {
					locationsByFields.contactCard = [STAR_CREATE_NEW_CARD]
				}
				if (!items || items.length === 0) {
					locationsByFields.cards = [STAR_ADD_NEW_ITEM]
				}
				if (hasPendingNewAction) {
					locationsByFields.cards = [STAR_CREATE_NEW_CARD, "pricingList"]
				}
			}
		}

		return locationsByFields
	}

	renderToolbar () {
		if (this.props.uiSize === "large" || this.state.view === Stack_View) {
			return <Toolbar>
				<div className="group">
					<button className="free-floating" onClick={this.onCloseStack.bind(this)} disabled={this.state.unsavedChanges || this.state.waitingServer}>{ t("close") }</button>
				</div>
				<div className="group">
					<StateButton className="free-floating notification" onClick={this.onPublish.bind(this)} disabled={this.state.unsavedChanges || this.props.stack.published} loading={this.state.savingToServer || this.state.publishingToServer}>
						{ t("publish") }
					</StateButton>
					<button className="free-floating" onClick={this.onShare.bind(this)}>{ t("sharePublicLink") }</button>
				</div>
				<div className="group">
					<CardCreate className="free-floating" onNewCard={cardType => this.onNewCard(cardType)} />
				</div>
			</Toolbar>
		} else {
			return <Toolbar>
				<div className="group">
					<button className="free-floating" onClick={() => this.setState({ view: Stack_View })}>{ t(Stack_View) }</button>
					<button className="free-floating" onClick={() => this.setState({ showPipPreview: !this.state.showPipPreview })}>{ t(this.state.showPipPreview ? 'hide_pip_preview' : 'show_pip_preview') }</button>
				</div>
			</Toolbar>
		}
	}

	render () {
		const card = this.getCard(this.props.currentCard)
		let Inspector = null
		if (card) {
			Inspector = inspectorsRegistry[card.type]
		}

		return (
			<div className={`Creator ${this.state.waitingServer && 'busy'} ${this.state.closing && 'closing'} view-${this.state.view} pip-${this.state.showPipPreview ? 'show' : 'hide'}`}>
				{ this.renderToolbar() }
				<div className="workspace">
					<div className={`stack ${this.props.currentCardLock > 0 && "locked"}`}>
						<CardList
							view="thumbnails"
							cards={this.props.stack.cards}
							value={this.props.currentCard}
							onChange={this.onCardSelectChange.bind(this)}
							animateIn={true}
							editable={true}
							onListReorder={this.onCardReorder.bind(this)}
							onDeleteItem={this.onCardDelete.bind(this)}
							onDuplicateItem={this.onCardDuplicate.bind(this)}
						/>
						<StackEmbed
							stackKey={this.props.stackKey}
							currentCard={this.props.currentCard}
							home={this.props.stack.home}
							cards={this.props.stack.cards}
							onGoToCard={cardId => this.props.setCurrentCard(cardId)}
						/>
					</div>
					{ Inspector && <Inspector
						key={this.props.currentCard}
						{...card}
						homeCardId={this.props.stack.home}
						onNameChange={this.onInspectorNameChange.bind(this)}
						onDataChange={this.onInspectorDataChange.bind(this)}
						onMakeHomeCard={id => {
							this.props.setStackHome(id)
							this.autoSave()
						}}
						devMode={this.props.account.settings.dev_mode}
						guidingStarLocations={this.getInspectorGuidingStarLocations()}
					/> }
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	sessionToken: state.session.token,
	account: state.account,
	stack: state.stack,
	currentCard: state.stack.currentCard,
	fullyLoaded: state.stack.fullyLoaded,
	stackTemplate: state.stack.template,
	currentCardLock: state.ui.currentCardLock,
	uiSize: state.ui.size
})

const mapDispatchToProps = { setStack, addStackCard, setStackCards, setStackHome, setStackPublished, setCurrentCard, updateSessionStackHome, bumpSessionStackToFirst, openPopup }

export default connect(mapStateToProps, mapDispatchToProps)(Creator)