export default {
	new_in_stacky: "Nuevo en Stacky",
	support: "Soporte",
	developerMode: "Modo desarrollador",
	developerModeOnMessage: "El modo desarrollador esta ahora activado, mostrando características avanzadas con las cuales desarrolladores pueden construir stacks dinámicos conectados a su APIs.",
	cardDataSource: "Origen de datos de la tarjeta",
	endpointURL: "URL del endpoint",
	name: "Nombre",
	title: "Título",
	image: "Imágen",
	body: "Cuerpo",
	type_here: "Escribí acá...",
	label: "Etiqueta",
	callsToAction: "Llamadas de acción",
	action_nx: "Acción #%{x}",
	image_nx: "Imágen #%{x}",
	action: "Acción",
	list_item_nx: "Item de lista #%{x}",
	card_nx: "Tarjeta #%{x}",
	x_card: "Tarjeta %{x}",
	shortDescription: "Descripción corta",
	cardShortDescriptionHint: "Usado como la descripción de esta tarjeta cuando es listada dentro de una Lista de Tarjetas.",
	description: "Descripción",
	qr_link: "Link QR",
	rename: "Renombrar",
	home: "Inicio",
	stack_name_warning: "Cambiar el nombre de tu stack va a romper los links y códigos QR que ya compartiste con otras personas. ¿Estás seguro de continuar?",
	stack_name_instructions: "Proveé un nombre que será visible en la dirección web de tu stack cuando la compartas. Podés usar caracteres alfanuméricos `A-Z 0-9` y guión bajo `_`",
	createNewCard: "Nueva tarjeta...",
	newXCard: "Nueva tarjeta de %{x}",
	chooseExistingCard: "Elegir tarjeta existente",
	clickToGoToCard: "Click para ir a la tarjeta",
	clickToChange: "Click para cambiar",
	clickToSelectCard: "Click para elegir una tarjeta",
	saveChanges: "Guardar cambios",
	show_pip_preview: "Mostrar vista previa",
	hide_pip_preview: "Ocultar vista previa",
	publish: "Publicar",
	close: "Cerrar",
	sharePublicLink: "Compartir link...",
	publicLink: "Link público",
	thisIsTheBody: "Este es el cuerpo de la tarjeta.",
	insertCode: "Insertar código",
	insertCodeHint: "Copie el código enviado a su correo y pégelo debajo para continuar.",
	card: "Tarjeta",
	cards: "Tarjetas",
	queryParams: "Parámetros de consulta",
	addNewItem: "Agregar nuevo item",
	addNewImage: "Agregar nueva imágen",
	duplicate: "Duplicar",
	remove: "Quitar",
	delete: "Eliminar",
	createStack: "Crear stack",
	imagesRatio: "Relación de aspecto de las imágenes",
	wide: "Ancho",
	square: "Cuadrado",
	items: "Ítems",
	banner: "Banner",
	banner_hint: "Mostrado en la parte superior de la tarjeta, detrás del título. También es usado como la imágen de la tarjeta cuando es mostrada en una lista de tarjetas.",
	branding_logotype: "Marca o logotipo",
	newStack: "Nuevo stack",
	price: "Precio",
	justNow: "Justo ahora",
	cantDeleteHomeCard: "No se puede borrar la tarjeta de inicio. Elige otra tarjeta como inicio primero.",
	confirmDeletingCard: "¿Está seguro que desea eliminar esta tarjeta?",
	other: "Otro",
	other_label: "Otra etiqueta",
	exit_preview_fullscreen: "Salir de vista previa",
	generic_server_error: "El servidor encontró un error. Por favor vuelva a intentarlo en un momento.",

	dropImageToReplace: "Suelta la imágen aquí para reemplazarla",
	clickToReplaceImage: "Click aquí para reemplazar la imágen",
	dropImageToInsert: "Suelta la imagen aquí para insertarla",
	dropImageOrChooseOne: "Suelta una imágen aquí o haz click para elegir una",
	fileUpload: "Subida de archivo",
	error_image_larger_than_maxSize: "La imágen no puede exceder los %{maxSize}",
	error_file_type_not_supported: "Este tipo de archivo no es soportado",

	signUpOrSignIn: "Registrarse o iniciar sesión",
	requestLoginCode: "Pedir código de ingreso",
	logout: "Cerrar sesión",
	
	thisIsHomeCard: "Esta es la tarjeta de inicio",
	makeThisHomeCard: "Hacer esta la tarjeta de inicio",
	cardId: "ID de la tarjeta",
	sample_structure: "Estructura de ejemplo",
	sample_structure_hint: "El endpoint debe devolver una estructura como la que se encuentra debajo para que la tarjeta visualize apropiadamente la información usando elementos de UI.",

	actionOnClick: "Acción al hacer click",
	action_none: "Ninguna acción",
	action_card: "Ir a tarjeta...",
	action_external: "Ir a sitio web externo...",
	action_phone: "Llamar número de teléfono...",
	action_address: "Abrir mapa en dirección...",
	action_email: "Enviar email a...",
	
	static: "Estático",
	staticWithUrlQuery: "Estático + Parámetros de consulta",
	urlQuery: "Parámetros de consulta",
	api: "API",

	add_object: "Agregar %{object}",
	object_nx: "%{object} #%{x}",
	addNewField: "Agregar nuevo campo",
	chooseAField: "Elegir un campo...",
	field: "Campo",
	fields: "Campos",
	phoneNumber: "Número de teléfono",
	emailAddress: "Dirección de correo electrónico",
	street: "Calle",
	city: "Ciudad",
	state: "Estado",
	country: "País",
	website_profile: "Red social",
	account_name: "Nombre de cuenta",
	messaging: "Mensajería",
	telegram_instructions: "Escribí tu nombre de usuario sin el símbolo @ inicial. Editá tu perfil en Telegrame para elegir tu nombre de usuario si no lo hiciste aún.",
	whatsapp_instructions: "Escribí tu número internacional completo para crear un link de WhatsApp válido. Por ejemplo: \"+54 9 11 3555-5555\"",
	titles_only: "Solo títulos",
	emailSubject: "Asunto del correo",
	optional: "Opcional",
	leave_empty_if_not_needed: "Dejar vacío si no lo necesitas",

	item_nx: "%{item} #%{x}",
	details: "Detalles",
	details_desc: "Mostrá un título, foto y texto. También puede agregar botones para hacer vínculos u otras acciones.",
	itemList: "Lista de ítems",
	itemList_desc: "Un listado de ítems con una foto, título y descripción. Opcionalmente, pueden tener un vínculo a otra tarjeta o sitio web.",
	cardList: "Lista de tarjetas",
	cardList_desc: "Un listado con vínculos a otras tarjetas. Incluye el título, foto y descripción de ellas automáticamente.",
	images: "Imágenes",
	images_desc: "Mostrá varias imágenes en tamaño completo.",
	qrCode: "Código QR",
	qrCode_desc: "Mostrá un código QR de cualquier link o texto que especifiques.",
	video: "Video",
	video_desc: "Muestra un video de YouTube.",
	musicAlbum: "Álbum de música",
	pricingList: "Listado de precios",
	pricingList_desc: "Un listado de producto o servicios con título, descripción, precio e imágen opcional.",
	contact: "Contacto",
	contact_desc: "Mostrá métodos de contacto como número de teléfono y dirección física.",
	landing: "Inicio",
	landing_desc: "Mostrá un logotipo, texto, una lista de links a otras tarjetas, y un botón de contacto.",

	guidingStarExplanation: "Una estrella te guiará a veces de acuerdo a la plantilla que elijas.",
	magic_templates: "Plantillas mágicas",
	pick_starting_card: "Elegí una tarjeta inicial",

	simple_restaurant: "Menu de bar o restaurante sencillo",
	simple_restaurant_desc: "Menú de una sola página, ideal para 20 ítems o menos.",
	restaurant_menu: "Menú de restaurante con categorías",
	restaurant_menu_desc: "Una lista de categorías, cada una vinculada a una lista de precios separada.",
	product_catalog: "Catálogo de productos",
	product_catalog_desc: "Una lista de productos, cada ítem vinculado a los detalles del producto.",
	landing_pricing_contact: "Página de inicio con precios y contacto",
	landing_pricing_contact_desc: "Una página de inicio donde recibir a tus visitantes con botones conectando a una lista de precios y contacto.",
	landing_categories_contact: "Página de inicio con categorías y contacto",
	landing_categories_contact_desc: "Una página de inicio para recibir a tus visitantes, con categorías de productos o servicios, y un botón de contacto.",

	home_landing_desc: "Ideal como una tarjeta de inicio para negocios, mostrando un logotipo, categorías de productos o servicios, y un botón de contacto.",
	home_details_desc: "Útil como una tarjeta de introducción que puede redireccionar a los usuarios a otras dos o tres tarjetas.",
	home_cardList_desc: "Una buena manera de vincular a múltiple otras tarjetas.",

	my_business: "Mi negocio",
	the_menu: "El menú",
	drinks_menu: "Menú de tragos",
	drinks: "Tragos",
	catalog: "Catálogo",
	product: "Producto",
	
	
	
	// Server errors.
	
	stack_not_found: "El stack no fue encontrado",
	stack_name_too_short: "El nombre del stack es muy corto",
	stack_name_taken: "El nombre del stack ya está tomando. Por favor elija otro",
	stack_restricted_access: "El acceso a este stack está restringido",



	// Non-translatable.

	twitter: "Twitter",
	facebook: "Facebook",
	youtube: "YouTube",
	instagram: "Instagram",
	stack: "Stack",
	telegram: "Telegram",
	whatsapp: "WhatsApp",



	// Shared.

	phone: "Teléfono",
	main_phone: "Teléfono principal",
	second_phone: "Teléfono secundario",
	address: "Dirección",
	email: "Email",
	external_link: "Link externo"
}