import React from 'react'
import TextInput from '../GUI/TextInput'
import ImageInput from '../GUI/ImageInput'
import CardInspector from './CardInspector'
import ActionInput from '../GUI/ActionInput'
import { t } from '../../intl'
import TextAreaInput from '../GUI/TextAreaInput'
import EditableListField from './EditableListField'
import CallsToAction from './Bits/CallsToAction'
import BannerInput from './Bits/BannerInput'
import RevealableField from './RevealableField'

export default class PricingListCardInspector extends CardInspector {
	renderFields () {
		return <>
			<RevealableField label={t("add_object", { object: t("banner") })} show={this.props.data.banner}>
				<div className="field">
					<BannerInput
						banner={this.props.data.banner}
						onDataChange={this.props.onDataChange}
					/>
				</div>
			</RevealableField>
			<RevealableField label={t("add_object", { object: t("shortDescription") })} show={this.props.data.description}>
				<div className="field">
					<label>{ t("shortDescription") }</label>
					<TextInput
						value={this.props.data.description}
						onChangeFinal={description => this.props.onDataChange({ description })}
						escCancelsEdit={true}
					/>
					<div className="hint">{ t("cardShortDescriptionHint") }</div>
				</div>
			</RevealableField>
			<EditableListField
				label={t("items")}
				items={this.props.data.items}
				newItemTemplate={{ label: null, link: null }}
				onChange={items => this.onListChange("items", items)}
				itemTitle={({ index }) => t("list_item_nx", { x: index + 1 })}
				component={Item}
				guidingStarLocations={this.props.guidingStarLocations.items}
			/>
			<CallsToAction
				alwaysShow={false}
				onListChange={this.onListChange.bind(this)}
				callsToAction={this.props.data.callsToAction}
			/>
		</>
	}

	static type = "pricingList"

	static getDefaultHash (cardId, cardName) {
		const props = super.getDefaultHash(cardId, cardName)
		props.data = {
			items: []
		}
		return props
	}

	static getAPISampleHash (cardName) {
		const props = super.getAPISampleHash(cardName)
		props.data = {
			title: "Prices",
			banner: "http://mydomain.com/myimage.jpg",
			description: "A pricing list.",
			items: [
				{
					title: "Fishes",
					description: "Pretty fishes.",
					price: "$20",
					image: "http://mydomain.com/myimage.jpg"
				},
				{
					title: "No image",
					description: "No pretty fishes.",
					price: "Free",
					action: {
						type: "card",
						cardId: "256cfbf31c3da67779300204e10052bf"
					}
				}
			]
		}
		return props
	}
}

const Item = props => {
	const { index, onListChange, image, title, price, description, action } = props
	return <div className="field">
		<div className="columns">
			<div className="column">
				<div className="label">
					{ t("image") }
					{ image && <button className="field-action free-floating" onClick={() => onListChange(index, { image: null })}>{ t("remove") }</button> }
				</div>
				<ImageInput
					value={image}
					onChange={image => onListChange(index, { image })}
				/>
			</div>
			<div className="column wide">
				<label className="field-label">{ t("title") }</label>
				<TextInput
					value={title}
					onChangeFinal={title => onListChange(index, { title })}
					escCancelsEdit={true}
				/>
				<label className="field-label">{ t("price") }</label>
				<TextInput
					value={price}
					onChangeFinal={price => onListChange(index, { price })}
					escCancelsEdit={true}
				/>
			</div>
		</div>
		<label className="field-label">{ t("description") }</label>
		<TextAreaInput
			key={`desc-${title}-${index}`}
			value={description}
			onChange={description => onListChange(index, { description })}
		/>
		<label>{ t("actionOnClick") }</label>
		<ActionInput
			{...action}
			onChange={action => onListChange(index, { action })}
		/>
	</div>
}
