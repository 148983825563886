import { getLocale } from "../intl"
import { Production_Viewer_URL } from "./server"

export function shallowDifferentObjectValues (obj1, obj2) {
	if (obj1 === null || obj2 === null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
		return obj1 !== obj2
	}
	const keys1 = Object.keys(obj1)
	if (keys1.length !== Object.keys(obj2).length) {
		return true
	}
	return keys1.some(key => obj1[key] !== obj2[key])
}

export function differentObjectValues (obj1, obj2, comparedKeys = null) {
	if (obj1 === null || obj2 === null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
		return obj1 !== obj2
	}
	const keys1 = Object.keys(obj1)
	if (!comparedKeys && keys1.length !== Object.keys(obj2).length) {
		return true
	}
	return (comparedKeys || keys1).some(key => differentObjectValues(obj1[key], obj2[key]))
}

export function getScrollParent (node) {
	if (node == null) {
		return null
	}
	if (node.scrollHeight > node.clientHeight) {
		return node
	} else {
		return getScrollParent(node.parentNode)
	}
}

export function openNewInStacky () {
	const stackId = getLocale() === "es"
		? "nuevo_en_stacky"
		: "whats_new"
	window.open(`${Production_Viewer_URL}/${stackId}`, "_blank")
}

export function openSupportStack () {
	const stackId = getLocale() === "es"
		? "ayuda"
		: "help"
	window.open(`${Production_Viewer_URL}/${stackId}`, "_blank")
}