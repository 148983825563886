import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import "./RichTextInput.scss"
import { Editor, EditorState, ContentState, RichUtils, convertFromHTML, KeyBindingUtil, getDefaultKeyBinding } from "draft-js"
import 'draft-js/dist/Draft.css'
import { insertNewUnstyledBlock } from "draftjs-utils"
import { stateToHTML } from "draft-js-export-html"

const BLOCK_TYPES = [
	"header-two",
	"header-three",
	"unordered-list-item",
	"ordered-list-item",
	"blockquote"
]

const STYLE_TYPES = [
	"bold",
	"italic",
	"underline",
	"code",
]

function RichTextInput ({ value, placeholder, onChange }) {
	const editorRef = useRef()
	const commitTimeout = useRef()
	const [ focus, setFocus ] = useState(false)
	const [ editorState, setEditorState ] = React.useState(value ? EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(value))) : EditorState.createEmpty())

	const inlineStyle = editorState.getCurrentInlineStyle()
	const blockType = editorState
		.getCurrentContent()
		.getBlockForKey(editorState.getSelection().getStartKey())
		.getType()

	const stopCommitTimeout = () => {
		clearTimeout(commitTimeout.current)
	}

	const onEditorChange = editorState => {
		setEditorState(editorState)
		stopCommitTimeout()
		commitTimeout.current = setTimeout(() => commitChanges(), 1000)
	}

	const commitChanges = () => {
		stopCommitTimeout()
		const newValue = editorState.getCurrentContent()
		const convertedValue = newValue.hasText() ? stateToHTML(newValue) : ""
		if (convertedValue !== value) {
			onChange(convertedValue)
		}
	}

	const focusEditor = () => {
		if (!focus) editorRef.current.focus()
	}

	const handleReturn = (e) => {
		if (e.shiftKey) {
			setEditorState(RichUtils.insertSoftNewline(editorState))
			return "handled"
		}
		else if (blockType.includes("header-")) {
			setEditorState(insertNewUnstyledBlock(editorState))
			return "handled"
		}
		return "not_handled"
	}

	const keyBindingFn = (e) => {
		if (e.key === "s" && KeyBindingUtil.hasCommandModifier(e)) {
			return "save"
		}
		if (e.key === "b" && KeyBindingUtil.hasCommandModifier(e)) {
			return "bold"
		}
		if (e.key === "i" && KeyBindingUtil.hasCommandModifier(e)) {
			return "italic"
		}
		if (e.key === "u" && KeyBindingUtil.hasCommandModifier(e)) {
			return "underline"
		}
		return getDefaultKeyBinding(e)
	}

	const handleKeyCommand = (command) => {
		console.log(command, blockType)
		switch (command) {
			case "save":
				commitChanges()
				break
			case "bold":
				toggleStyle("BOLD")
				break
			case "italic":
				toggleStyle("ITALIC")
				break
			case "underline":
				toggleStyle("UNDERLINE")
				break
			default:
				return "not-handled"
		}
		return "handled"
	}

	const toggleStyle = style => {
		onEditorChange(RichUtils.toggleInlineStyle(
			editorState,
			style
		))
	}

	const toggleBlock = type => {
		onEditorChange(RichUtils.toggleBlockType(
			editorState,
			type
		))
	}

	const onFocus = () => {
		setFocus(true)
	}

	const onBlur = () => {
		setFocus(false)
		commitChanges()
	}

	return <div className={`RichTextInput ${focus && "focus"}`} onClick={focusEditor}>
		<div className="toolbar">
			<div className="group">
				{
					BLOCK_TYPES.map(type => <StyleButton key={type} onClick={() => toggleBlock(type)} active={blockType === type}>
						<span className={`icon icon-${type}`}></span>
					</StyleButton>)
				}
			</div>
			<div className="group">
				{
					STYLE_TYPES.map(type => <StyleButton key={type} onClick={() => toggleStyle(type.toUpperCase())} active={inlineStyle.has(type.toUpperCase())}>
						<span className={`icon icon-${type}`}></span>
					</StyleButton>)
				}
			</div>
		</div>
		<Editor
			ref={editorRef}
			handleReturn={handleReturn}
			editorState={editorState}
			onChange={editorState => onEditorChange(editorState)}
			keyBindingFn={keyBindingFn}
			handleKeyCommand={handleKeyCommand}
			onFocus={onFocus}
			onBlur={onBlur}
			placeholder={placeholder}
		/>
	</div>
}

function StyleButton ({ children, active, onClick }) {
	return <button
		className={active ? "active" : ""}
		onMouseDown={e => e.preventDefault()}
		onClick={e => {
			e.preventDefault()
			onClick()
		}}
	>{ children }</button>
}

RichTextInput.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func
}

export default RichTextInput