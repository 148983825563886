import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { setAccount, setSessionToken, addSessionStack } from "../actions"
import StackPreview from "./StackPreview"
import { axios_instance } from "../util/server"
import { t } from "../intl"
import "./StackSelect.scss"
import Toolbar from "./GUI/Toolbar"
import StateButton from "./GUI/StateButton"
import ToggleInput from "./GUI/ToggleInput"
import { openNewInStacky, openSupportStack, } from "../util"

const LATEST_WHATS_NEW = "simplified_addresses"

class StackSelect extends Component {
	static propTypes = {
		sessionToken: PropTypes.string,
		stacks: PropTypes.array,
		onStackSelect: PropTypes.func,
		onCreateNewStack: PropTypes.func
	}

	state = {
		loggingOut: false,
		updatingSettings: false,
		hasUnseenWhatsNew: this.hasUnseenWhatsNew()
	}
	
	hasUnseenWhatsNew () {
		return localStorage.getItem("latestWhatsNew") !== LATEST_WHATS_NEW
	}

	onLogOut () {
		this.setState({ loggingOut: true })
		axios_instance.post("/logout", { session_token: this.props.sessionToken })
			.then(() => {
				localStorage.removeItem("sessionToken")
				this.props.setSessionToken(null)
			})
			.finally(() => {
				this.setState({ loggingOut: false, waitingServer: false })
			})
	}
	
	onWhatsNew () {
		localStorage.setItem("latestWhatsNew", LATEST_WHATS_NEW)
		this.setState({ hasUnseenWhatsNew: false })
		openNewInStacky()
	}

	setDevMode (value) {
		const settings = {
			...this.props.account.settings,
			dev_mode: value
		}
		this.setState({ updatingSettings: true })
		axios_instance.post("/account/settings", {
			session_token: this.props.sessionToken,
			settings
		})
			.then(response => {
				const account = response.data.account
				if (account) {
					this.props.setAccount(account)
					localStorage.setItem("account", JSON.stringify(account))
					if (account.settings.dev_mode) {
						setTimeout(() => alert(t("developerModeOnMessage")), 250)
					}
				}
			})
			.catch(console.error)
			.finally(() => {
				this.setState({ updatingSettings: false })
			})
	}

	render () {
		return <div className="StackSelect">
			<Toolbar>
				<div className="group">
					<button className={`free-floating ${this.state.hasUnseenWhatsNew && "hot"}`} onClick={this.onWhatsNew.bind(this)}>{ t("new_in_stacky") }</button>
					<button className="free-floating" onClick={() => openSupportStack()}>{ t("support") }</button>
				</div>
				<div className="group">
					<div>
						<ToggleInput
							label={t("developerMode")}
							value={this.props.account.settings.dev_mode}
							onChange={value => this.setDevMode(value)}
							loading={this.state.updatingSettings}
						/>
					</div>
					<StateButton className="free-floating" onClick={this.onLogOut.bind(this)} loading={this.state.loggingOut}>
						{ t("logout") }
					</StateButton>
				</div>
			</Toolbar>
			<div className="stacks">
				<StackPreview placeholder={true} name={t("createStack")} onSelect={() => this.props.onCreateNewStack()} />
				{
					this.props.stacks.map(stack => (
						<StackPreview {...stack} key={stack.key} stackKey={stack.key} onSelect={() => this.props.onStackSelect(stack.key, stack.home_card)} />
					))
				}
			</div>
		</div>
	}
}

const mapStateToProps = state => ({
	sessionToken: state.session.token,
	account: state.account
})

const mapDispatchToProps = { setAccount, setSessionToken, addSessionStack }

export default connect(mapStateToProps, mapDispatchToProps)(StackSelect)