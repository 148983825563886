export default {
	new_in_stacky: "New in Stacky",
	support: "Support",
	developerMode: "Developer mode",
	developerModeOnMessage: "Developer mode is now enabled, displaying advanced features for developers to build dynamic stacks connected to APIs.",
	cardDataSource: "Card data source",
	endpointURL: "Endpoint URL",
	name: "Name",
	title: "Title",
	image: "Image",
	body: "Body",
	type_here: "Type here...",
	label: "Label",
	callsToAction: "Calls to action",
	action_nx: "Action #%{x}",
	image_nx: "Image #%{x}",
	action: "Action",
	list_item_nx: "List item #%{x}",
	card_nx: "Card #%{x}",
	x_card: "%{x} card",
	shortDescription: "Short description",
	cardShortDescriptionHint: "Used as this cards description when listed inside a Cards List.",
	description: "Description",
	qr_link: "QR Link",
	rename: "Rename",
	home: "Home",
	stack_name_warning: "Changing the name of your stack will break existing links and QR codes you already shared with other people. Do you wish to proceed?",
	stack_name_instructions: "Provide a name that will be visible in the web address of your stack when you share it. You may use alphanumeric characters `A-Z 0-9` and underscore `_`",
	createNewCard: "New card...",
	newXCard: "New %{x} card",
	chooseExistingCard: "Choose existing card",
	clickToGoToCard: "Click to go to card",
	clickToChange: "Click to change",
	clickToSelectCard: "Click to select a card",
	saveChanges: "Save changes",
	show_pip_preview: "Show preview",
	hide_pip_preview: "Hide preview",
	publish: "Publish",
	close: "Close",
	sharePublicLink: "Share link...",
	publicLink: "Public link",
	thisIsTheBody: "This is the card body.",
	insertCode: "Insert code",
	insertCodeHint: "Copy the code sent to your email address and paste it below to proceed.",
	card: "Card",
	cards: "Cards",
	queryParams: "Query parameters",
	addNewItem: "Add new item",
	addNewImage: "Add new image",
	duplicate: "Duplicate",
	remove: "Remove",
	delete: "Delete",
	createStack: "Create stack",
	imagesRatio: "Images aspect ratio",
	wide: "Wide",
	square: "Square",
	items: "Items",
	banner: "Banner",
	banner_hint: "Shown at the top of the card, behind the title. Also used as the cards image when shown in a card list.",
	branding_logotype: "Branding or logotype",
	newStack: "New stack",
	price: "Price",
	justNow: "Just now",
	cantDeleteHomeCard: "Can't delete the home card. Make another card the home card first.",
	confirmDeletingCard: "Are you sure you wish to delete this card?",
	other: "Other",
	other_label: "Other label",
	exit_preview_fullscreen: "Exit preview",
	generic_server_error: "The server encountered an error. Please try again in a moment.",

	dropImageToReplace: "Drop image here to replace it",
	clickToReplaceImage: "Click here to replace the image",
	dropImageToInsert: "Drop image here to insert it",
	dropImageOrChooseOne: "Drop an image here or click to choose one",
	fileUpload: "File upload",
	error_image_larger_than_maxSize: "Image cannot exceed %{maxSize}",
	error_file_type_not_supported: "File type not supported",

	signUpOrSignIn: "Sign up or sign in",
	requestLoginCode: "Request login code",
	logout: "Log out",
	
	thisIsHomeCard: "This is the home card",
	makeThisHomeCard: "Make this the home card",
	cardId: "Card ID",
	sample_structure: "Sample structure",
	sample_structure_hint: "The endpoint must return a structure like the one below for this card to properly visualize the data with UI elements.",

	actionOnClick: "Action on click",
	action_none: "No action",
	action_card: "Go to card...",
	action_external: "Go to external website...",
	action_phone: "Call phone number...",
	action_address: "Open map at address...",
	action_email: "Send email at...",
	
	static: "Static",
	staticWithUrlQuery: "Static + URL Query",
	urlQuery: "URL Query",
	api: "API",

	add_object: "Add %{object}",
	object_nx: "%{object} #%{x}",
	addNewField: "Add new field",
	chooseAField: "Choose a field...",
	field: "Field",
	fields: "Fields",
	phoneNumber: "Phone number",
	emailAddress: "Email address",
	street: "Street",
	city: "City",
	state: "State",
	country: "Country",
	website_profile: "Social media",
	account_name: "Account name",
	messaging: "Messaging",
	telegram_instructions: "Write your username without the initial @ symbol. Edit your profile on Telegram to get a username if you haven't already.",
	whatsapp_instructions: "Write your full international phone number to create a valid WhatsApp link. I.e: \"+1 (415) 999-9999\"",
	titles_only: "Titles only",
	emailSubject: "Email subject",
	optional: "Optional",
	leave_empty_if_not_needed: "Leave empty if you do not need it",

	item_nx: "%{item} #%{x}",
	details: "Details",
	details_desc: "Show a title, picture, and text rich body. Can also add buttons to make links or other actions.",
	itemList: "Item list",
	itemList_desc: "A list of items with a picture, title and description. Optionally, they can be linked to another card or website.",
	cardList: "Card list",
	cardList_desc: "A list with links to other cards. Includes their title, picture and description automatically.",
	images: "Images",
	images_desc: "Display several pictures in full size.",
	qrCode: "QR Code",
	qrCode_desc: "Displays a QR Code of any link or text that you specify.",
	video: "Video",
	video_desc: "Shows a YouTube video.",
	musicAlbum: "Music album",
	pricingList: "Pricing list",
	pricingList_desc: "A list of products or services with a title, description, price and picture.",
	contact: "Contact",
	contact_desc: "Display contact methods like phone number and physical address.",
	landing: "Landing",
	landing_desc: "Display a logotype, text, a list of links to other cards, and a button for contact.",

	guidingStarExplanation: "A star will sometimes guide you based on your chosen template.",
	magic_templates: "Magic templates",
	pick_starting_card: "Pick a starting card",

	simple_restaurant: "Simple restaurant or bar menu",
	simple_restaurant_desc: "Single page menu, ideal for 20 items or less.",
	restaurant_menu: "Restaurant menu with categories",
	restaurant_menu_desc: "A list of categories, each linking to a separate price list.",
	product_catalog: "Product catalog",
	product_catalog_desc: "A list of products, each linking to a card with details of the product.",
	landing_pricing_contact: "Landing page with pricing and contact",
	landing_pricing_contact_desc: "A landing page to receive your visitors with buttons connecting to a pricing list and contact.",
	landing_categories_contact: "Landing page with categories and contact",
	landing_categories_contact_desc: "A landing page to receive your visitors, with a list of product or service categories, and contact button.",

	home_landing_desc: "Ideal as a landing card for a business, showcasing a logotype, list of product or service categories, and a contact button.",
	home_details_desc: "Useful as an introduction card that can redirect users to two or three other cards.",
	home_cardList_desc: "A great way to link to multiple other cards.",

	my_business: "My business",
	the_menu: "The menu",
	drinks_menu: "Drinks menu",
	drinks: "Drinks",
	catalog: "Catalog",
	product: "Product",
	
	
	
	// Server errors.
	
	stack_not_found: "Stack not found",
	stack_name_too_short: "Stack name is too short",
	stack_name_taken: "Stack name is already taken. Please choose a different one",
	stack_restricted_access: "Stack access is restricted",



	// Non-translatable.

	twitter: "Twitter",
	facebook: "Facebook",
	youtube: "YouTube",
	instagram: "Instagram",
	stack: "Stack",
	telegram: "Telegram",
	whatsapp: "WhatsApp",



	// Shared.

	phone: "Phone",
	main_phone: "Main phone",
	second_phone: "Second phone",
	address: "Address",
	email: "Email",
	external_link: "External link"
}